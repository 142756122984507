import * as React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

// COMPONENTS
import { Input } from 'components/styled/input'
import { Button } from 'components/styled/button'
import SubscriptionCard from 'components/subscription/subscriptionCard'
import ResetPasswordVerification from 'components/resetPassword/resetPasswordVerification'

// ASSETS
import { SCREEN } from 'styles/screens'
import axios from 'axios'
import { config } from '../../config'
import { useState } from 'react'
import * as queryString from 'querystring'

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin: 8px 0;
    flex: 1 1 80%;
  }

  > button {
    width: 100%;
    margin: 12px 0;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > div {
      margin: 2px 0;
    }

    > button {
      margin: 12px 0 0 0;
    }
  }
`

const StyledP = styled.p`
  margin: 8px 0;
  text-align: center;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 4px 0;
  }
`

const ForgotPasswordContainer = styled.small`
  text-align: right;
  width: 100%;
  margin: 0;
  cursor: pointer;
`

const defaultRegisterFields = { fullName: '', email: '', password: '', linkedin:'' }
const defaultLoginFields = { email: '', password: '' }

const RegistrationForm = ({
  isOpen = false,
  signInForm,
  setSignInForm,
  handleModal,
  signIn,
}) => {
  const [forgetPass, setForgetPass] = React.useState(false)
  const [registerFields, setRegisterFields] = React.useState({
    ...defaultRegisterFields,
  })
  const [passphrase, setPassphrase] = useState('')
  const [loginFields, setLogiFields] = React.useState({ ...defaultLoginFields })
  const [errors, setErrors] = React.useState({})
  const [joining, setJoining] = React.useState(false)

  const validate = () => {
    let e = {}
    if (!registerFields.fullName) {
      e.fullName = 'Valid name is required'
    }
    if (!registerFields.email) {
      e.email = 'Valid email is required'
    }
    if (!registerFields.password) {
      e.password = 'Password is required'
    }

    if(passphrase === 'upbuildproduct' && !registerFields.linkedin){
      e.linkedin = 'Linkedin profile is required'
    }
    setErrors(e)
    return Object.keys(e).length === 0
  }

  const onJoin = async () => {
    if (!validate()) {
      return
    }
    setJoining(true)
    try {
      let user = (
        await axios.post(`${config.API_BASE_URL}/users`, { ...registerFields, passphrase })
      ).data
      setJoining(false)
      if (user.error) {
        alert(user.error)
        return
      }
      window.localStorage.setItem('user', JSON.stringify(user))
      window.localStorage.setItem('token', user.apiTokens.pop())
      window.localStorage.setItem('registered', '1')

      setLogiFields({ ...defaultLoginFields })
      const {next} = queryString.parse(window.location.search.substr(1));
      if(next){
        window.location.href = next;
      }else {
        window.location.href = `/profile`
      }
      setRegisterFields({ ...defaultRegisterFields })
    } catch (e) {
      setJoining(false)
      alert(JSON.stringify(e.data))
    }
  }

  const onLogin = async () => {
    setJoining(true)
    try {
      let user = (
        await axios.post(`${config.API_BASE_URL}/users/login`, loginFields)
      ).data
      window.localStorage.setItem('user', JSON.stringify(user))
      window.localStorage.setItem('token', user.apiTokens.pop())
      window.localStorage.setItem('registered', '1')
      setJoining(false)
      setLogiFields({ ...defaultLoginFields })

      const {next} = queryString.parse(window.location.search.substr(1));
      if(next){
        window.location.href = next;
      }else {
        window.location.href = `/profile`
      }
    } catch (error) {
      setJoining(false)
      alert('Invalid credentials')
    }
  }

  const onChangeRegisterFields = e => {
    let r = { ...registerFields }
    r[e.target.id] = e.target.value
    setRegisterFields(r)
  }

  const onChangeLoginFields = e => {
    let r = { ...loginFields }
    r[e.target.id] = e.target.value
    setLogiFields(r)
  }

  const isCorrectPassphrase = ['upbuildproduct','mybuildup'].indexOf(passphrase) > -1;
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Registration Modal'}
      ariaHideApp={false}
      className="react_modal_register"
      overlayClassName="react_modal_register_overlay"
      closeTimeoutMS={1000}
      shouldFocusAfterRender={false}
    >
      {forgetPass ? (
        <ResetPasswordVerification
          onClose={() => handleModal(false)}
          onBack={() => {
            setForgetPass(false)
          }}
          onFinish={() => {
            handleModal(false)
            // wait for closing animation to finish then reset the state of the modal
            setTimeout(() => {
              setSignInForm(false)
              setForgetPass(false)
            }, 1000)
          }}
        />
      ) : (
        <SubscriptionCard
          titles={
            signInForm
              ? [
                  { inverted: true, content: 'YESS!!!' },
                  { inverted: false, content: 'ENTER NOW' },
                ]
              : [
                  { inverted: true, content: 'REGISTER' },
                  { inverted: false, content: 'WITH US' },
                ]
          }
          isLeft={false}
          onClose={() => handleModal(false)}
        >
          {signInForm ? (
            <InputsContainer>
              <Input
                id="email"
                value={loginFields.email}
                onChange={onChangeLoginFields}
                label="Email"
                placeholder="example@email.com"
                type="email"
              />
              <Input
                id="password"
                value={loginFields.password}
                onChange={onChangeLoginFields}
                label="Password"
                placeholder="••••••••"
                type="password"
              />

              <ForgotPasswordContainer>
                <span
                  tabIndex={0}
                  role="button"
                  aria-hidden="true"
                  onClick={() => setForgetPass(true)}
                >
                  Forgot password?
                </span>
              </ForgotPasswordContainer>
              <Button disabled={joining} onClick={onLogin}>
                {joining ? 'Signing in' : 'Sign in'}
              </Button>
            </InputsContainer>
          ) : (

            <InputsContainer>
              {isCorrectPassphrase &&
              <>
                <Input label='Full Name' error={errors.fullName} id='fullName' value={registerFields.fullName}
                       onChange={onChangeRegisterFields}
                       placeholder='your name' type='text' />
                <Input id='email' error={errors.email} onChange={onChangeRegisterFields} value={registerFields.email}
                       label='Email'
                       placeholder='example@email.com'
                       type='email'
                />
                {passphrase === 'upbuildproduct' &&
                <Input id='linkedin' error={errors.linkedin} onChange={onChangeRegisterFields} value={registerFields.linkedin}
                       label='Linkedin Profile'
                       placeholder='https://linkedin.com/in/username'
                       type='url'
                />
                }

                <Input id={'password'} error={errors.password} onChange={onChangeRegisterFields}
                       value={registerFields.password} label='Password'
                       placeholder='••••••••' type='password' />
                <Button onClick={onJoin} disabled={joining}>{joining ? 'Joining' : 'Join'}</Button>
              </>}

              {!isCorrectPassphrase &&
              <>
                <Input label='Passphrase' error={errors.passphrase} id='passphrase' value={passphrase}
                       onChange={(e)=>{
                         setPassphrase(e.target.value);
                       }
                       }
                       placeholder='passphrase' type='text' />

                <Button>Continue</Button>
              </>}


            </InputsContainer>
          )}
          {signInForm ? (
            <StyledP>
              Not a member?{' '}
              <span
                tabIndex={0}
                role="button"
                aria-hidden="true"
                onClick={() => setSignInForm(false)}
              >
                Join
              </span>
            </StyledP>
          ) : (
            <StyledP>
              Already a member?{' '}
              <span
                tabIndex={0}
                role="button"
                aria-hidden="true"
                onClick={() => setSignInForm(true)}
              >
                Sign in
              </span>
            </StyledP>
          )}
        </SubscriptionCard>
      )}
    </Modal>
  )
}

export default RegistrationForm
