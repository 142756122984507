import * as React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Header from 'components/shared/header'
import Footer from 'components/shared/footer'
import MobileCTA from 'components/shared/mobileCTA'
import RegistrationForm from 'components/registration/form'
import * as queryString from 'querystring'

const Main = ({
  children,
  title = '',
  showFooter = true,
  hideTitlePrefix = false,
  ogImage,
}) => {
  const [regModalIsOpen, setRegModalIsOpen] = React.useState(false)
  const [signInForm, setSignInForm] = React.useState(false)
  const [mobileCTAVisible, setMobileCTAVisible] = React.useState(true)
  const [signedIn, setSignedIn] = React.useState(false)
  const [user, setUser] = React.useState({})

  React.useEffect(() => {
    let { showLogin } = queryString.parse(window.location.search.substr(1))
    if (showLogin) {
      setRegModalIsOpen(true)
      setSignInForm(true)
    }

    if (typeof document !== undefined && typeof window !== undefined) {
      try {
        const user = JSON.parse(window.localStorage.getItem('user'))
        setUser(user)
        setSignedIn(typeof user.firstName !== 'undefined')
      } catch (e) {
        setUser({})
        setSignedIn(false)
      }
    }
  }, [])

  const onSignOut = () => {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    window.location.href = '/'
  }
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>
          {hideTitlePrefix ? '' : 'BuildUp | '}
          {title}
        </title>
        <meta
          name="description"
          content="Project-Based bootcamp for the top 5%"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <meta
          property="og:image:url"
          content={
            ogImage ||
            'https://d34rq7yialevbd.cloudfront.net/uploads/dc25f9f7-092e-416d-bd62-edbf542d231a'
          }
        />
        <meta
          property="og:title"
          content={`${hideTitlePrefix ? 'BuildUp | ' : ''}${title}`}
        />
        <meta
          property="og:description"
          content="Project-Based bootcamp for the top 5%"
        />
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&family=Montserrat:wght@700&display=swap"
          rel="stylesheet"
        /> */}
        <meta name="robots" content="index,follow" data-rh="true" />
        <meta name="googlebot" content="index,follow" data-rh="true" />
      </Helmet>
      <div
        css={`
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-between;
        `}
      >
        <Header
          handleModal={type => {
            setSignInForm(type === 'login')
            setRegModalIsOpen(true)
          }}
          signedIn={signedIn}
          user={user}
          signOut={onSignOut}
        />
        <div
          css={`
            padding-top: var(--header);
            flex: 1 0;
          `}
        >
          {typeof children === 'function'
            ? children({
                setRegModalIsOpen,
                regModalIsOpen,
                setMobileCTAVisible,
              })
            : children}
        </div>
        {mobileCTAVisible && (!user || Object.keys(user).length === 0) && (
          <MobileCTA handleModal={setRegModalIsOpen} />
        )}
        {showFooter && <Footer />}
        <RegistrationForm
          signInForm={signInForm}
          setSignInForm={setSignInForm}
          isOpen={regModalIsOpen}
          handleModal={setRegModalIsOpen}
          signIn={() => setSignedIn(true)}
        />
      </div>
    </>
  )
}

export default Main
