import styled from 'styled-components'

export const Button = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${({ secondary }) =>
    secondary ? 'transparent' : 'var(--secondary)'};
  color: var(--primary);
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 3px solid var(--secondary);
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 ${({ secondary }) => (secondary ? 'var(--secondary)' : 'rgba(255,255,255,0.1)')};
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`

export const BlackButton = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 1px solid #000;
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 var(--secondary);
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`
export const DisabledButton = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #a4a4a4;
  color: var(--primary);
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;

  &:hover {
    box-shadow: inset 0 -3.25em 0 0 ${({ secondary }) => (secondary ? 'var(--secondary)' : 'rgba(255,255,255,0.1)')};
  }

  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`
export const ButtonLink = styled.a`
  display: inline-flex;
  text-decoration: none;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${({ secondary }) =>
    secondary ? 'transparent' : 'var(--secondary)'};
  color: var(--primary);
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 3px solid var(--secondary);
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 ${({ secondary }) => (secondary ? 'var(--secondary)' : 'rgba(255,255,255,0.1)')};
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`

export const SecondaryButton = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background-color: transparent;
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 3px solid var(--secondary);
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 ${'var(--secondary)'};
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`
export const SecondaryButtonLink = styled.a`
  display: inline-flex;
  position: relative;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  color: var(--primary);
  background-color: transparent;
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 3px solid var(--secondary);
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    box-shadow: inset 0 -3.25em 0 0 ${'var(--secondary)'};
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`

export const PinkButton = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #FF7999;
  padding: calc(var(--spacing) * 2.5) calc(var(--spacing) * 6);
  border: 1px solid #000;
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    border-color: #FF7999;
    background-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
    font-size: 16px;
  }
`
