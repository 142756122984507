const calculateBeblinTestResult = (checklist = []) => {

  const roleData = {
    'SH': {
      name: 'Shaper',
      characteristics: 'Highly strung, outgoing, dynamic.',
      description: 'Shapers are highly motivated people with a lot of nervous energy and a great need for achievement.\n' +
        'Often they seem to be aggressive extroverts with strong drive. Shapers like to challenge, to lead\n' +
        'and to push others into action - and to win. If obstacles arise, they will find a way round - but can\n' +
        'be headstrong and emotional in response to any form of disappointment or frustration.\n' +
        'Shapers can handle and even thrive on confrontation.',
      functionText: 'Shapers generally make good managers because they generate action and thrive on pressure. They\n' +
        'are excellent at sparking life into a team and are very useful in groups where political complications\n' +
        'are apt to slow things down. Shapers are inclined to rise above problems of this kind and forge\n' +
        'ahead regardless. They like making necessary changes and do not mind taking unpopular decisions.\n' +
        'As the name implies, they try to impose some shape and pattern on group discussion or activities.\n' +
        'They are probably the most effective members of a team in guaranteeing positive action.',
      strengths: 'Drive and a readiness to challenge inertia, ineffectiveness, complacency or self-deception.',
      weakness: 'Prone to provocation, irritation and impatience, and a tendency to offend others.',
    },
    'PL': {
      name: 'Plant',
      characteristics: 'Individualistic, serious-minded, unorthodox.',
      description: "The distinguishing feature of Co-ordinators is their ability to cause others to work to shared goals.\n" +
        "Mature, trusting and confident, they delegate readily. In interpersonal relations they are quick to spot\n" +
        "individual talents and to use them to pursue group objectives. While Co-ordinators are not\n" +
        "necessarily the cleverest members of a team, they have a broad and worldly outlook and generally\n" +
        "command respect.",
      functionText: "Co-ordinators are useful people to have in charge of a team with diverse skills and personal\n" +
        "characteristics. They perform better in dealing with colleagues of near or equal rank than in directing\n" +
        "junior subordinates. Their motto might well be “consultation with control” and theyusually believe\n" +
        "in tackling problems calmly. In some organisations, Co-ordinators are inclined to clash with Shapers\n" +
        "due to their contrasting management styles.",
      strengths: 'Welcome all potential contributors on their merits and without prejudice, but without ever losing sight\n' +
        'of the main objective.',
      weakness: 'No pretensions as regards intellectual or creative ability',
    },
    'ME': {
      name: 'Monitor Evaluator',
      characteristics: 'Sober, unemotional, prudent.',
      description: "Monitor Evaluators are serious-minded, prudent individuals with a built-in immunity from being overenthusiastic. They are slow deciders who prefer to think things over - usually with a high critical\n" +
        "thinking ability. Good Monitor Evaluators have a capacity for shrewd judgements that take all factors\n" +
        "into account and seldom give bad advice.",
      functionText: "Monitor Evaluators are at home when analysing problems and evaluating ideas and suggestions.\n" +
        "They are very good at weighing up the pro’s and con’s of options and to outsiders seem dry, boring\n" +
        "or even over-critical. Some people are surprised that they become managers. Nevertheless, many\n" +
        "Monitor Evaluators occupy key planning and strategic posts and thrive in high-level appointments\n" +
        "where a relatively small number of decisions carry major consequences.",
      strengths: 'Judgement, discretion, hard-headedness.',
      weakness: 'Lack of inspiration or the ability to motivate others.  ',
    },
    'RI': {
      name: 'Resource Investigator',
      characteristics: 'Extroverted, enthusiastic, curious, communicative.',
      description: "Resource Investigators are good communicators both inside and outside the organisation. They are\n" +
        "natural negotiators, adept at exploring new opportunities and developing contacts. Although not\n" +
        "necessarily a great source of original ideas, they are quick to pick up other people’s ideas and build\n" +
        "on them. They are skilled at finding out what is available and what can be done, and usually get a\n" +
        "warm welcome because of their outgoing nature.\n" +
        "Resource Investigators have relaxed personalities with a strong inquisitive sense and a readiness\n" +
        "to see the possibilities of anything new. However, unless they remain stimulated by others, their\n" +
        "enthusiasm rapidly fades.",
      functionText: "Resource Investigators are quick to open up and exploit opportunities. They have an ability to think\n" +
        "on their feet and to probe others for information. They are the best people to set up external contacts,\n" +
        "to search for resources outside the group, and to carry out any negotiations that may be involved.",
      strengths: 'A capacity for finding useful people and promising ideas or opportunities, and a general source of\n' +
        'vitality.',
      weakness: 'Liable to lose interest once the initial fascination has passed.',
    },
    'IMP': {
      name: 'Implementer',
      characteristics: "Implementers are well organised, enjoy routine, and have a practical common-sense and selfdiscipline. They favour hard work and tackle problems in a systematic fashion. On a wider front they\n" +
        "hold unswerving loyalty to the organisation and are less concerned with the pursuit of self- interest.\n" +
        "However, Implementers may find difficulty in coping with new situations.",
      description: '-',
      functionText: "Implementers are useful because of their reliability and capacity for application. They succeed\n" +
        "because they have a sense of what is feasible and relevant. It is said that many executives only do\n" +
        "the jobs they wish to do and neglect those tasks which they find distasteful. By contrast,\n" +
        "Implementers will do what needs to be done. Good Implementers often progress to high\n" +
        "management positions by virtue of good organisational skills and efficiency in dealing with all\n" +
        "necessary work.",
      strengths: "Organising ability, practical common sense, hard working, self-discipline.",
      weakness: "Lack of flexibility, resistance to unproven ideas.",
    },
    'TW': {
      name: 'Team Worker',
      characteristics: 'Socially oriented, rather mild and sensitive.',
      description: "Team Workers are the most supportive members of a team. They are mild, sociable andconcerned\n" +
        "about others with a great capacity for flexibility and adapting to different situations and people. Team\n" +
        "Workers are perceptive and diplomatic. They are good listeners and are generally popular members\n" +
        "of a group. They cope less well with pressure or situations involving the need for confrontation.",
      functionText: "The role of the Team Worker is to prevent interpersonal problems within a team and allow everyone\n" +
        "to contribute effectively. Since they don’t like friction, they will go to great lengths to avoid it. The\n" +
        "diplomatic and perceptive skills of a Team Worker become real assets, especially under a\n" +
        "managerial regime where conflicts are liable to arise or to be artificially suppressed.Team\n" +
        "Worker managers are seen as a threat to no one and therefore can be elected as the most accepted\n" +
        "and favoured people to serve under. Team Workers have a lubricating effect on teams. Morale is\n" +
        "better and people seem to co-operate better when they are around.",
      strengths: "Ability to respond to people and situations and to promote team spirit.",
      weakness: "Indecision at moments of crisis and some failure to provide a clear lead to others.  ",
    },
    'CF': {
      name: 'Completer-Finisher',
      characteristics: 'Painstaking, orderly, conscientious, anxious.',
      description: "Completers, or Completer-Finishers, have a great capacity for follow-through and attention to detail,\n" +
        "and seldom start what they cannot finish. They are motivated by internal anxiety, although outwardly\n" +
        "they may appear unruffled. Typically, they are introverts who don’t need much external stimulus or\n" +
        "incentive. Completer-Finishers dislike carelessness and are intolerant of those with a casual\n" +
        "disposition. Reluctant to delegate, they prefer to tackle all tasks themselves.",
      functionText: "Completer-Finishers are invaluable where tasks demand close concentration and a high degree\n" +
        "of accuracy. They foster a sense of urgency within a team and are good at meeting schedules.\n" +
        "In management, they excel by the high standards to which they aspire, and by their concern for\n" +
        "precision, attention to detail and follow-through.",
      strengths: 'A capacity for fulfilling their promises and working to the highest standards.',
      weakness: 'A tendency to worry about small things and a reluctance to “let go”.  ',
    },
    'CO': {
      name: 'Co-ordinator',
      characteristics: 'Calm, self-confident, controlled.',
      description: "The distinguishing feature of Co-ordinators is their ability to cause others to work to shared goals.\n" +
        "Mature, trusting and confident, they delegate readily. In interpersonal relations they are quick to spot\n" +
        "individual talents and to use them to pursue group objectives. While Co-ordinators are not\n" +
        "necessarily the cleverest members of a team, they have a broad and worldly outlook and generally\n" +
        "command respect.",
      functionText: "Co-ordinators are useful people to have in charge of a team with diverse skills and personal\n" +
        "characteristics. They perform better in dealing with colleagues of near or equal rank than in directing\n" +
        "junior subordinates. Their motto might well be “consultation with control” and theyusually believe\n" +
        "in tackling problems calmly. In some organisations, Co-ordinators are inclined to clash with Shapers\n" +
        "due to their contrasting management styles.",
      strengths: "Welcome all potential contributors on their merits and without prejudice, but without ever losing sight\n" +
        "of the main objective.",
      weakness: "No pretensions as regards intellectual or creative ability.",
    },
  }


  let map = [
    ['IMP', 'CF', 'SH', 'PL', 'ME', 'RI', 'CO', 'TW'],
    ['SH', 'CF', 'TW', 'ME', 'PL', 'CO', 'IMP', 'RI'],
    ['CF', 'RI', 'ME', 'CO', 'IMP', 'PL', 'SH', 'TW'],
    ['IMP', 'SH', 'CO', 'RI', 'ME', 'PL', 'CF', 'TW'],
    ['PL', 'TW', 'ME', 'IMP', 'CO', 'SH', 'RI', 'CF'],
    ['RI', 'CF', 'ME', 'CO', 'PL', 'SH', 'TW', 'IMP'],
    ['IMP', 'ME', 'RI', 'CF', 'SH', 'PL', 'CO', 'TW'],
  ]

  const points = {
    'IMP': 0, 'CF': 0, 'SH': 0, 'PL': 0, 'ME': 0, 'RI': 0, 'CO': 0, 'TW': 0,
  }

  Object.keys(checklist).forEach((key, sectionIndex) => {
    checklist[key].questions.forEach(({ answer }, questionIndex) => {
      points[map[sectionIndex][questionIndex]] += parseInt(answer)
    })
  })

  let top = Object.keys(points).reduce((a, b) => points[a] > points[b] ? a : b)
  delete points[top]
  let secondTop = Object.keys(points).reduce((a, b) => points[a] > points[b] ? a : b)
  return [roleData[top]];
}

export default calculateBeblinTestResult
