import * as axios from 'axios'
import calculateBeblinTestResult from './calculateBeblinTestResult'

export default class Api {
  constructor() {
    this.api_token = null
    this.client = null

    this.api_url = 'https://backend.buildup.am'
  }

  static getInstance() {
    return new Api()
  }

  init(authorize = true) {
    //    this.api_token = localStorage.getItem('token');

    const headers = {
      Accept: 'application/json',
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers,
    })

    return this.client
  }

  post(url, data, authorize = true) {
    return new Promise((resolve, reject) => {
      this.init(authorize)
        .post(url, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          reject(e.response ? e.response.data : {})
        })
    })
  }

  get(url, params) {
    return new Promise((resolve, reject) => {
      this.init()
        .get(url, { params })
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          reject(e.response ? e.response.data : {})
        })
    })
  }

  delete(url) {
    return new Promise((resolve, reject) => {
      this.init()
        .delete(url)
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          reject(e.response ? e.response.data : {})
        })
    })
  }

  getStudents() {
    return this.get('/tp/students')
  }

  getStudentImages() {
    return this.get('/users/students/images')
  }

  getTeams() {
    return this.get('/tp/teams')
  }

  getStudent(id) {
    return this.get(`/tp/student/${id}`)
  }

  getStudentObjectives(id) {
    return this.get(`/tp/student/${id}/objectives`)
  }

  getTeam(id) {
    return this.get(`/tp/team/${id}`)
  }

  getTeamObjectives(id) {
    return this.get(`/tp/team/${id}/objectives`)
  }

  getTeamStudents(id) {
    return this.get(`/tp/team/${id}/students`)
  }

  getStudentGrades(program, sort) {
    return this.get(`/cauldron/api/applicantData?page=1&limit=100&title=${program}&chronology=${sort}`)
  }

  getBeblinTestResult = async (email) => {
    let { applicants } = await this.get(`/cauldron/api/applicantData?page=1&limit=100&title=selfPerception&chronology=descending&checklist=true`)
    let checklist = []
    applicants.forEach(applicant => {
      if (applicant.email === email) {
        checklist = applicant.checklist
      }
    });

    return calculateBeblinTestResult(checklist[0])
  }


  getStudentComments(email) {
    return this.get(`/comments/email?email=${email}`)
  }
}
